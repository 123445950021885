import { render, staticRenderFns } from "./PmDataSourceTypeForm.vue?vue&type=template&id=728adb10&scoped=true"
import script from "./PmDataSourceTypeForm.vue?vue&type=script&lang=js"
export * from "./PmDataSourceTypeForm.vue?vue&type=script&lang=js"
import style0 from "./PmDataSourceTypeForm.vue?vue&type=style&index=0&id=728adb10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728adb10",
  null
  
)

export default component.exports